<template>
	<v-container
		id="SessionList"
		fluid
		tag="section">
		<v-overlay
			z-index="60"
			v-model="loaderlay">
			<v-progress-circular
				indeterminate
				size="100"
				color="primary"
				class="l2">
				<v-progress-circular
					indeterminate
					size="75"
					color="primary"
					class="l3"></v-progress-circular>
			</v-progress-circular>
		</v-overlay>
		<v-row dense>
			<v-col>
				<v-card class="v-card--material true pa-3 py-3">
					<card-heading
						:color="sectionColor"
						title="Sessions">
						<template #append>
							<ConfirmDialog2
								persistent
								v-if="
									_usrFlagsSome({
										key: `session.list`,
										val: permissions.READ,
									})
								"
								:confirmBtnText="`Export anyway`"
								:confirmBtnColor="'error'"
								:cancelBtnColor="'kajot-text'"
								:cancelBtnText="'Back'"
								:shouldShow="
									(filterIsEmpty && !dontShow) || total_records > 4000
								"
								@confirm="onExportOpen">
								<template v-slot:body>
									<b>
										Exporting large amounts of data may significantly impact
										server load and increase wait times.
									</b>
									<br />
									Do you wish to proceed?
									<v-row
										class="d-flex flex-row align-center justify-space-around">
										<v-col cols="1">
											<v-checkbox v-model="dontShow"></v-checkbox>
										</v-col>
										<v-col>
											<span>Don't ask again in this session</span>
										</v-col>
									</v-row>
								</template>
								<template v-slot:default="{ showConfirm }">
									<v-btn
										@click="showConfirm"
										fab
										color="primary"
										small>
										<v-icon>mdi-arrow-down-bold</v-icon>
									</v-btn>
								</template>
							</ConfirmDialog2>
						</template>
					</card-heading>
					<v-data-table
						must-sort
						sort-by="start_time"
						:sort-desc="true"
						:headers="filteredHeaders"
						:items="list"
						:item-class="itemClass"
						:options.sync="options"
						:server-items-length="total_records"
						:loading="loading"
						:footer-props="footerProps"
						class="elevation-1"
						@click:row="handleClick">
						<template #top="{ pagination }">
							<div class="d-flex justify-space-between align-center">
								<v-form
									ref="search"
									class="col-3 pa-0 ml-6"
									v-model="valid">
									<v-row
										align="center"
										class="pa-0">
										<v-tooltip
											color="primary darken-2"
											bottom>
											<template v-slot:activator="{ on, attrs }">
												<span
													v-bind="attrs"
													v-on="on">
													<v-icon
														size="large"
														:color="`primary ${
															$vuetify.theme.dark ? '' : 'darken-2'
														}`"
														class="mr-1">
														mdi-help-circle
													</v-icon>
												</span>
											</template>
											<span>
												Search Key can be either of:
												<br />
												InternalID, ExternalID, MongoID, Token, ExternalTicketID
											</span>
										</v-tooltip>
										<v-text-field
											label="Search"
											v-model="searchKey"
											v-if="
												_usrFlagsSome({
													key: `session.detail`,
													val: permissions.READ,
												})
											"
											:error-messages="searchErrs"
											:dark="$vuetify.theme.dark"
											:light="!$vuetify.theme.dark"
											@blur="
												() => {
													valid = true;
													(searchRules = []), (searchErrs = []);
												}
											"
											@input="
												() => {
													valid = true;
													(searchRules = []), (searchErrs = []);
												}
											"
											@keydown.enter.prevent="
												(e) =>
													onSubmit(
														searchKey,
														loadSessionSearch,
														sessionSearch,
														handleClick,
														e
													)
											"
											:rules="searchRules"></v-text-field>
									</v-row>
								</v-form>
								<v-data-footer
									class="anton"
									:options.sync="options"
									v-bind="footerProps"
									:pagination="pagination"></v-data-footer>
							</div>
						</template>
						<template v-slot:[`item.session_mongo_id`]="{ item }">
							<div class="d-flex flex-row align-center">
								<v-btn
									icon
									small
									class="elevation-0 transparent"
									color="kajot-text"
									@click.prevent.stop="copySession(item.session_mongo_id)">
									<v-icon small>mdi-content-copy</v-icon>
								</v-btn>
								<v-tooltip
									:open-delay="250"
									:disabled="!shouldShortenString(item.session_mongo_id)"
									top>
									<template #activator="{ on, attrs }">
										<span
											v-on="on"
											v-bind="attrs">
											{{ item.session_mongo_id | ellipsis }}
										</span>
									</template>
									<span>{{ item.session_mongo_id }}</span>
								</v-tooltip>
							</div>
						</template>
						<template v-slot:[`item.external`]="{ item }">
							<div class="d-flex flex-row align-center">
								<v-btn
									icon
									small
									class="elevation-0 transparent"
									color="kajot-text"
									@click.prevent.stop="copySession(item.external)">
									<v-icon small>mdi-content-copy</v-icon>
								</v-btn>
								<v-tooltip
									:open-delay="250"
									:disabled="!shouldShortenString(item.external)"
									top>
									<template #activator="{ on, attrs }">
										<span
											v-on="on"
											v-bind="attrs">
											{{ item.external | ellipsis }}
										</span>
									</template>
									<span>{{ item.external }}</span>
								</v-tooltip>
							</div>
						</template>
						<template v-slot:[`item.external_ticket`]="{ item }">
							<v-tooltip
								:open-delay="250"
								:disabled="!shouldShortenString(item.external_ticket)"
								top>
								<template #activator="{ on, attrs }">
									<span
										v-on="on"
										v-bind="attrs">
										{{ item.external_ticket | ellipsis }}
									</span>
								</template>
								<span>{{ item.external_ticket }}</span>
							</v-tooltip>
						</template>
						<template #[`item.player_mongo_id`]="{ item }">
							<div class="d-flex flex-row align-center">
								<v-btn
									icon
									small
									class="elevation-0 transparent"
									color="kajot-text"
									@click.prevent.stop="copySession(item.player_mongo_id)">
									<v-icon small>mdi-content-copy</v-icon>
								</v-btn>
								<v-tooltip
									:open-delay="250"
									:disabled="!shouldShortenString(item.player_mongo_id)"
									top>
									<template #activator="{ on, attrs }">
										<span
											v-on="on"
											v-bind="attrs">
											{{ item.player_mongo_id | ellipsis
											}}{{ item.player ? ` / ${item.player}` : `` }}
										</span>
									</template>
									<span>
										{{ item.player_mongo_id
										}}{{ item.player ? ` / ${item.player}` : `` }}
									</span>
								</v-tooltip>
							</div>
						</template>
						<template v-slot:[`item.start_time`]="{ item }">
							{{ item.start_time | Date }}
						</template>
						<template v-slot:[`item.end_time`]="{ item }">
							{{ item.end_time | Date }}
						</template>
						<template v-slot:[`item.sum_bet`]="{ item }">
							{{ item.sum_bet | Number({ currency: item.currency }) }}
						</template>
						<template v-slot:[`item.sum_win`]="{ item }">
							{{ item.sum_win | Number({ currency: item.currency }) }}
						</template>
						<template v-slot:[`item.netto`]="{ item }">
							{{ item.netto | Number({ currency: item.currency }) }}
						</template>
						<template v-slot:[`item.finished`]="{ item }">
							<v-icon
								v-if="item.end_time"
								color="primary lighten-1">
								mdi-check
							</v-icon>
							<v-icon
								v-else
								color="error">
								mdi-close
							</v-icon>
						</template>
						<template v-slot:[`item.actions`]="{ item }">
							<v-tooltip
								bottom
								color="primary darken-2">
								<template #activator="{ on, attrs }">
									<v-icon
										color="primary"
										size="22"
										v-bind="attrs"
										v-on="on"
										@click.stop.prevent="onRowClick(item)">
										mdi-alpha-i-circle
									</v-icon>
								</template>
								<span>Detail</span>
							</v-tooltip>
						</template>
					</v-data-table>
				</v-card>
			</v-col>

			<v-snackbar
				style="position: fixed; bottom: 20px; text-align: center"
				app
				transition="slide-y-reverse-transition"
				:timeout="1000"
				color="menu_background"
				content-class="kajot-text--text"
				v-model="copied">
				<div class="align-center">
					<v-icon color="info">mdi-information</v-icon>
					Copied to clipboard.
				</div>
				<template #action>
					<v-btn
						fab
						text
						x-small
						@click="copied = false">
						<v-icon
							color="kajot-text"
							small>
							mdi-close
						</v-icon>
					</v-btn>
				</template>
			</v-snackbar>
		</v-row>
		<FilterSideBar
			:filterIsEmpty="filterIsEmpty"
			refreshButton
			@clear="clearFilter"
			@refresh="reloadSessions"
			:loading="loading"
			:width="isMobile ? '' : '400px'">
			<SessionListForm
				ref="filter"
				:filterIsEmpty="filterIsActive"
				@filter="useFilter"
				:loading="loading"
				v-model="filter" />
		</FilterSideBar>
		<v-dialog
			class="my-2 fakin-dialog"
			:width="1200"
			:max-width="1200"
			v-model="sessionModal">
			<SessionDetailModal
				:item="selectedSession"
				:key="selectedSession"
				@close="dialog = false"></SessionDetailModal>
		</v-dialog>
		<v-dialog
			@click:outside="exportClose"
			@keydown.esc="exportClose"
			max-width="500px"
			v-model="exportDialog">
			<ExportDialog
				@close="exportClose"
				builder="paginatedObject"
				delimiter=";"
				:data="exportData"
				:filename="`sessions`"
				ref="exportDialog"></ExportDialog>
		</v-dialog>
	</v-container>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from "vuex";
import CardHeading from "../../components/shared/CardHeading.vue";
import permissions from "../../mixins/permissions.js";
import SessionListForm from "../../components/sessions/SessionListForm.vue";
import SessionDetailModal from "../../components/sessions/SessionDetailModal.vue";
import { shouldShortenString, isMobile } from "../../constants/helpers";
import table from "../../mixins/table.js";
import localstorage from "../../mixins/localstorage.js";
import _ from "lodash";
import FilterSideBar from "../../components/shared/FilterSideBar.vue";
import { rules as defaultRules } from "../../plugins/rules.ts";
import ExportDialog from "../../components/shared/ExportDialog.vue";
import ConfirmDialog2 from "../../components/shared/ConfirmDialog2.vue";

export default {
	components: {
		CardHeading,
		SessionListForm,
		FilterSideBar,
		SessionDetailModal,
		ExportDialog,
		ConfirmDialog2,
	},
	mixins: [permissions, table, localstorage],
	data() {
		return {
			dontShow: sessionStorage.getItem("logan2:exportDialog:hide") ?? false,
			exportDialog: false,
			loaderlay: false,
			selectedSession: undefined,
			sessionModal: false,
			copied: false,
			valid: false,
			searchRules: [],
			searchErrs: [],
			rules: {
				...defaultRules,
			},
			searchKey: "",
			filterActive: false,
			filter: {
				min_bet: undefined,
				max_bet: undefined,
				// min_round_bet: undefined,
				// max_round_bet: undefined,
				min_win: undefined,
				max_win: undefined,
				min_games: undefined,
				max_games: undefined,
				date_from: undefined,
				date_to: undefined,
				games: [],
				currency: [],
			},
			sessionFilterExpanded: false,
			total_records: 0,
			sectionColor: "secondary",
			headers: [
				{ text: "Session ID", value: "session_mongo_id", align: "center" },
				{
					text: "External",
					value: "external",
					align: "center",
					sortable: false,
					hide: () => !this.list[0]?.external,
				},
				{
					text: "External ticket",
					value: "external_ticket_id",
					align: "center",
					sortable: false,
					hide: () => !this.list[0]?.external_ticket_id,
				},
				{ text: "Game", value: "game_name", align: "left" },
				{ text: "Player", value: "player_mongo_id", align: "left" },
				{ text: "Start Time", value: "start_time", align: "center" },
				{ text: "End Time", value: "end_time", align: "center" },
				{ text: "Σ Bet", value: "sum_bet", align: "right" },
				{ text: "Σ Win", value: "sum_win", align: "right" },
				{ text: "Netto", value: "netto", align: "right" },
				{
					text: "Finished",
					value: "finished",
					sortable: false,
					align: "center",
				},
				{ text: "Round Count", value: "rounds_count", align: "right" },
				{ text: "Actions", value: "actions", align: "center" },
			],
		};
	},
	computed: {
		isMobile,
		filterIsEmpty() {
			return (
				_.isEqual(this.getFilter, {
					min_bet: undefined,
					max_bet: undefined,
					min_win: undefined,
					max_win: undefined,
					min_games: undefined,
					max_games: undefined,
					// min_round_bet: undefined,
					// max_round_bet: undefined,
					date_from: undefined,
					date_to: undefined,
					currency: [],
					games: [],
				}) ||
				_.isEqual(this.getFilter, {
					currency: [],
					games: [],
				})
			);
		},
		filterIsActive() {
			return _.isEqual(this.getFilter, this.filter);
		},
		exportData() {
			return {
				headers: [
					["session_mongo_id", "Session Mongo Id"],
					["player_mongo_id", "Player Mongo Id"],
					["game_name", "Game Name"],
					["game_id", "Game Id"],
					["player", "Player"],
					["start_time", "Start Time"],
					["end_time", "End Time"],
					["internal", "Internal"],
					["external", "External"],
					["currency", "Currency"],
					["sum_bet", "Sum Bet"],
					["sum_win", "Sum Win"],
					["netto", "Netto"],
					["rounds_count", "Rounds Count"],
					["external_ticket_id", "External Ticket Id"],
				],
				filter: this.getFilter,
				url: `${this.loganUrl}/api/v1/casinos/${this.currentCasino}/sessions/filter`,
				token: this.accessHeaders,
			};
		},
		...mapGetters(["currentCasino", "sessionSearch", "loganUrl"]),
		...mapGetters("apiCall", {
			accessHeaders: "accessHeaders",
		}),
		rowIsClickable() {
			return this._usrFlagsSome({
				key: "session.detail",
				val: this.permissions.READ,
			});
		},
		...mapGetters("sessions2", {
			list: "list",
			getFilter: "filter",
			getPagination: "pagination",
		}),
		sessionListStuff() {
			return {
				options: this.options,
				filter: this.filter,
			};
		},
		filteredHeaders() {
			return this.headers.filter((el) => !el.hide?.());
		},
	},
	methods: {
		onRowClick(i) {
			this.selectedSession = i;
			this.sessionModal = true;
		},
		exportClose(e) {
			this.exportDialog = false;
			this.$refs.exportDialog.onClose();
		},
		onExportOpen() {
			sessionStorage.setItem("logan2:exportDialog:hide", this.dontShow);
			this.exportDialog = true;
			setTimeout(() => {
				this.$refs.exportDialog.onExportOpen();
			}, 500);
		},
		copySession(sessionId) {
			navigator.clipboard.writeText(sessionId);
			this.timeout = this.timeout === 1000 ? 1001 : 1000;
			this.copied = true;
		},
		reloadSessions() {
			if (this.options.page != 1) {
				this.options.page = 1;
				return;
			}
			this._loadTable();
		},
		...mapActions({
			setMessage: "notification/setMessage",
		}),
		...mapActions(["loadSessionSearch"]),
		async onSubmit(val, searchFn, searchGetter, routingFn, e) {
			this.searchRules = [this.rules.no_spaces, this.rules.minMaxLength(3)];
			this.$refs.search.validate();
			await this.$nextTick();

			if (!this.valid) return;
			this.loaderlay = true;

			await searchFn({ searchKey: val, noErr: true });

			if (!searchGetter?.data) {
				this.loaderlay = false;
				this.searchErrs = ["Specified session does not exist."];
			}

			if (searchGetter?.data?.length === 1) {
				this.loaderlay = false;
				routingFn(searchGetter.data[0], undefined, e);
				return;
			}
			this.loaderlay = false;
		},
		async useFilter(e) {
			this.loading = true;
			this.setFilter(this.filter);
			this._loadTable();
		},
		clearFilter() {
			this.$refs.filter.onClear();
			this.useFilter();
		},
		itemClass() {
			return `table-item${this.rowIsClickable ? " table-item--clickable" : ""}`;
		},
		...mapActions("sessions2", {
			loadList: "loadList",
		}),
		...mapMutations("sessions2", {
			setPagination: "pagination",
			setFilter: "filter",
		}),
		async handleClick(item, _, e) {
			if (
				!this._usrFlagsSome({
					key: "session.detail",
					val: this.permissions.READ,
				})
			)
				return;

			if (e.ctrlKey === true || e.metaKey === true) {
				const tempRoute = this.$router.resolve({
					name: "session-detail",
					params: {
						session_id: item.session_mongo_id,
						casino: this.currentCasino,
						breadcrumbs: { title: `Session ${item.session_mongo_id}` },
					},
				});
				window.open(tempRoute.href);
			} else {
				try {
					await this.$router.push({
						name: "session-detail",
						params: {
							session_id: item.session_mongo_id,
							casino: this.currentCasino,
							breadcrumbs: { title: `Session ${item.session_mongo_id}` },
						},
					});
				} catch (error) {
					this.loaderlay = false;
				}
			}
		},
		shouldShortenString,
	},
	async created() {
		this.$syncWithStorage(["filter"], this.$route.name);

		this.filter = this.$getFromStorage("filter") ?? this.filter;
		this.setFilter(this.filter);
	},
	watch: {
		async currentCasino() {
			if (this.options.page === 1) {
				const paging = await this.loadList({ filter: this.filter });
				this.total_records = paging.total_records;
			} else {
				this.options.page = 1;
			}
		},
	},
};
</script>

<style scoped lang="sass">

::v-deep .v-dialog
	overflow-y: visible !important


::v-deep
	@for $i from 1 through 3
		& .l#{$i}>svg
			$dur:4s
			$offset: .3
			$delay: ($i * $offset) - 3s
			animation-duration: $dur
			animation-delay: $delay
			animation-direction: alternate
			animation-timing-function: ease-in-out
			@if $i % 2 == 0
				animation-direction: alternate-reverse
			.v-progress-circular__overlay
				animation-duration: $dur
				animation-delay: $delay
				animation-direction: alternate
				animation-timing-function: ease-in-out
				@if $i % 2 == 0
					animation-direction: alternate-reverse
</style>
